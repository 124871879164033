import axios from "../../kit/axios_config";
export default {
  // 查询单位
  getUnits(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/selectCommodityMetering", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  //一键分拣
  sortingAllGoods(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/onePieceSorting", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  //商品分拣
  goodsSorting(data) {
    return new Promise((resolve) => {
      axios
        .get("/commoditySorting/sortingCommodity", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 打印模板
  printTemplate(type, orderCommodityId) {
    return new Promise((resolve) => {
      axios
        .get("/print/printSortingNo", {
          params: {
            type,
            orderCommodityId,
          },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 一键分拣打印数据
  oneKeyPrint(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/printOnePieceSortingByClient", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 打印
  print(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/selectPrintInfo", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 点击商品分拣查询接口
  getTypeList(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/select", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 获取分拣商品
  getSortingGoods(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/selectCommodityList", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 进入分拣详情
  getSortingGoodsDetail(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/selectCommodity", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 点击分类查看商品列表接口
  getListByType(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/select", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 点击商品查询分拣列表接口
  getListByGoods(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/select", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 根据商品ID查询商品名称接口
  selectCommodityMetering(commodityId) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/selectCommodityMetering", {
          params: { commodityId },
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 点击操作分拣商品接口查询接口
  viewDetails(orderCommodityId) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/viewDetails", { params: { orderCommodityId } })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 重置商品分拣接口
  resetSorting(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/resetSorting", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 标记缺货接口
  signOutStock(orderCommodityId) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/signOutStock", { params: { orderCommodityId } })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 多次分拣接口
  manyTimesSorting(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/manyTimesSorting", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 分拣商品接口
  sortingCommodity(data) {
    return new Promise((resolve) => {
      axios
        .get("/sortByCommodity/sortingCommodity", {
          params: data,
        })
        .then((res) => {
          resolve(res.data);
        });
    });
  },
  // 查询送货时间接口
  selectDeliveryTime() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/selectDeliveryTime").then((res) => {
        resolve(res.data);
      });
    });
  },
  // 查询仓库接口
  selectWarehouse() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/selectWarehouse").then((res) => {
        resolve(res.data);
      });
    });
  },
  // 查询线路接口
  selectDeliveryLine() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/selectDeliveryLine").then((res) => {
        resolve(res.data);
      });
    });
  },
  // 查询供应商接口
  queryProvider() {
    return new Promise((resolve) => {
      axios.get("/sortByCommodity/queryProvider").then((res) => {
        resolve(res.data);
      });
    });
  },
};
