<template>
  <!-- 按商品分拣 -->
  <div style="background-color:#ddd;height:100vh">
    <!-- 顶部栏 -->
    <div class="a">
      <div style="width:7%;display:flex">
        <div
          style="display:flex;align-items:center;margin:auto"
          @click="$router.push('index')"
        >
          <img src="../assets/back.png" alt style="width:30px;height:30px" />
          <div style="color:#fff;font-size:20px">返回</div>
        </div>
      </div>
      <div style="display:flex;width:70%;align-items: center;">
        <el-input
          placeholder="请输入客户名称"
          prefix-icon="el-icon-search"
          v-model="search"
          style="width:600px;margin:auto"
          @input="select"
        ></el-input>
        <div style="width: 100px;color: #fff;display: flex;">
          <div
            style="width: 20px;height: 20px;background-color:rgb(197,74,74);"
          ></div>
          待分拣
        </div>
        <div style="width: 100px;color: #fff;display: flex;">
          <div
            style="width: 20px;height: 20px;background-color:rgb(188,188,0);"
          ></div>
          部分分拣
        </div>
        <div style="width: 100px;color: #fff;display: flex;">
          <div
            style="width: 20px;height: 20px;background-color:rgb(66,180,133);"
          ></div>
          已分拣
        </div>
      </div>
      <div
        style="display:flex;width:30%;margin-left:10px;flex-flow: row-reverse;font-size:15px"
      >
        <div class="b" style="background-color:rgb(197,74,74);font-size:15px">
          <div class="yjfj" @click="yjfj()" style="font-size:20px">
            一键分拣
          </div>
        </div>
        <div class="b" style="background-color:rgb(66,151,178);font-size:15px">
          <div class="sx" @click="drawer = true" style="font-size:20px">
            筛选
          </div>
        </div>
        <div class="b" style="background-color:rgb(66,180,133);font-size:15px">
          <div class="yjdy" @click="yjdy" style="font-size:20px">一键打印</div>
        </div>
      </div>
    </div>
    <!-- 顶部栏 -->
    <div style="display:flex;flex-direction:row;">
      <div class="c" v-if="searchFlag">
        <div
          style="height: 80px;display:flex;border-bottom: 1px solid grey;color:white;box-sizing:border-box;"
          v-for="(item, index) in radioData"
          :key="index"
        >
          <div style="margin:auto">
            <el-radio-group v-model="radio" :key="index" @change="radioChange">
              <el-radio :label="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div style="width:85%;background-color: #ddd;">
        <div
          style="height: 50px;color:rgb(197,74,74);font-size:20px;font-weight:bold;display:flex;align-items:center;justify-content:center;"
        >
          <span>发货日期：</span>
          <span>{{ shipmentsDate }}</span>
        </div>
        <div style="height:80vh;overflow: auto;">
          <div v-for="(item, index) in typeList" :key="index">
            <div
              @click="
                shouqiweishouqi(item.firstTypeId, item.secondTypeId, index)
              "
              style="height: calc(6vh);font-size:20px;margin:10px;display:flex;flex-direction:row;justify-content:space-between;align-items:center;border-bottom:1px solid grey"
            >
              <div style="font-weight:bold">
                {{ item.firstTypeName + " - " + item.secondTypeName }}
              </div>
              <div
                style="width:100px;height:33px;background-color:rgb(66,180,133);display:flex;border-radius:50px;"
              >
                <div style="margin:auto;color:#000" v-if="radioIndex == index">
                  - 收起
                </div>
                <div style="margin:auto;color:#000" v-else>+ 展开</div>
              </div>
            </div>
            <div
              v-if="radioIndex == index"
              style="display:flex;flex-wrap: wrap;"
            >
              <div
                v-for="(item, index) in goodsList"
                :key="index"
                style="margin:10px"
              >
                <div @click="goToAspfjDetail(item)">
                  <div style="width:130px;height:90px;border:1px solid grey">
                    <div
                      style="width:40%;height:30%;background-color:rgb(66,180,133);display:flex;"
                      v-if="item.numberSorted == item.unselectedNumber"
                    >
                      <div style="margin:auto;color:#000;font-size:10px">
                        已完成
                      </div>
                    </div>
                    <div
                      style="width:40%;height:30%;background-color:rgb(197,74,74);display:flex;"
                      v-if="item.numberSorted == 0"
                    >
                      <div style="margin:auto;color:#000;font-size:10px">
                        未分拣
                      </div>
                    </div>
                    <div
                      style="width:40%;height:30%;background-color:rgb(188,188,0);display:flex;"
                      v-if="
                        item.numberSorted * 1 > 0 &&
                          item.numberSorted * 1 < item.unselectedNumber * 1
                      "
                    >
                      <div style="margin:auto;color:#000;font-size:10px">
                        部分分拣
                      </div>
                    </div>
                    <div style="width:100%;height:40%;display:flex;">
                      <div style="margin:auto;font-size:12px">
                        {{ item.commodityName }}
                      </div>
                    </div>
                    <div
                      style="width:100%;height:30%;background-color:rgb(66,180,133);display:flex;"
                      v-if="item.numberSorted == item.unselectedNumber"
                    >
                      <div style="margin:auto;color:#000;font-size:15px">
                        分拣进度:{{ item.numberSorted }}/{{
                          item.unselectedNumber
                        }}
                      </div>
                    </div>
                    <div
                      style="width:100%;height:30%;background-color:rgb(197,74,74);display:flex;"
                      v-if="item.numberSorted == 0"
                    >
                      <div style="margin:auto;color:#000;font-size:15px">
                        分拣进度:{{ item.numberSorted }}/{{
                          item.unselectedNumber
                        }}
                      </div>
                    </div>
                    <div
                      style="width:100%;height:30%;background-color:rgb(188,188,0);display:flex;"
                      v-if="
                        item.numberSorted * 1 > 0 &&
                          item.numberSorted * 1 < item.unselectedNumber * 1
                      "
                    >
                      <div style="margin:auto;color:#000;font-size:15px">
                        分拣进度:{{ item.numberSorted }}/{{
                          item.unselectedNumber
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 筛选栏 -->
    <el-drawer title="筛选" :visible.sync="drawer" size="70%">
      <div>
        <div>
          <div style="margin:10px 0 0 10px;font-weight:bold">发货日期</div>
          <div style="display:flex;flex-direction:row">
            <div
              style="width:120px;height:40px;border:1px solid grey;display:flex;position:relative;margin:10px"
              v-for="(item, index) in dateData"
              :key="index"
              @click="fhrqdj(index)"
            >
              <div
                style="margin:auto;color:rgb(66,151,178)"
                v-if="dateIndex == index"
              >
                {{ item }}
              </div>
              <div style="margin:auto" v-else>{{ item }}</div>
              <img
                src="../assets/gou.png"
                alt
                style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                v-if="dateIndex == index"
              />
            </div>
            <div style="width:120px;height:60px;margin:10px">
              <el-date-picker
                v-model="shipmentsDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
          </div>
        </div>
        <div style="display:flex;flex-direction:row">
          <div style="display:flex">
            <div style="margin:10px 0 0 10px;font-weight:bold">分拣状态</div>
            <div style="display:flex;flex-direction:row">
              <el-select v-model="fjztValue" placeholder="请选择">
                <el-option
                  v-for="item in fjztData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div style="display:flex">
            <div style="margin:10px 0 0 10px;font-weight:bold">是否标品</div>
            <div style="display:flex;flex-direction:row">
              <el-select v-model="sfbpValue" placeholder="请选择">
                <el-option
                  v-for="item in sfbpData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div>
          <div style="margin:10px 0 0 10px;font-weight:bold">更多筛选条件</div>
          <div style="margin:10px">
            <el-select v-model="shsjValue" placeholder="请选择收货时间">
              <el-option
                v-for="item in shsjData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select
              v-model="warehouseValue"
              placeholder="请选择仓库"
              style="margin-left:10px"
            >
              <el-option
                v-for="item in warehouseData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div>
          <div style="margin:10px 0 0 10px;font-weight:bold">请选择路线</div>
          <div style="margin-left:10px;margin-top:10px">
            <el-select v-model="path" placeholder="请选择">
              <el-option
                v-for="item in lxData"
                :key="item.lineId"
                :label="item.lineName"
                :value="item.lineId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <div style="margin:10px 0 0 10px;font-weight:bold">请选择供应商</div>
          <div style="margin-left:10px;margin-top:10px">
            <el-select v-model="providerId" placeholder="请选择">
              <el-option
                v-for="item in gysData"
                :key="item.providerId"
                :label="item.providerName"
                :value="item.providerId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div style="display:flex;flex-direction:row;justify-content:flex-end;">
          <div
            style="width:250px;height:60px;display:flex;border:1px solid black;margin-right:20px"
            @click="() => (drawer = false)"
          >
            <div style="margin:auto">取消</div>
          </div>
          <div
            @click="sure()"
            style="width:250px;height:60px;display:flex;background-color:rgb(66,151,178);margin-right:20px"
          >
            <div style="margin:auto;color:#000">确定</div>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 筛选栏 -->

    <!-- 一键分拣提示框 -->
    <el-dialog title="提示" :visible.sync="printTipDialogSorting" width="30%">
      <span>
        将有
        {{ num }}
        个标签被打印（注意：此功能会分拣并打印所有未分拣的商品），确定全部打印？
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="printTipDialogSorting = false">取消</el-button>
        <el-button type="primary" @click="yjfj(1)">确定</el-button>
      </span>
    </el-dialog>
    <!-- 一键分拣提示框 -->
    <!-- 一键打印提示弹框 -->
    <el-dialog title="提示" :visible.sync="printTipDialog" width="30%">
      <span>是否确定一键打印{{ num }}个标签</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="printTipDialog = false">取消</el-button>
        <el-button type="primary" @click="print()">确定</el-button>
      </span>
    </el-dialog>
    <!-- 一键打印提示弹框 -->
  </div>
  <!-- 按商品分拣 -->
</template>

<script>
import { PrintAccount } from "../print/doPrint";
import api from "../apis/aspfj/index";
export default {
  data() {
    return {
      drawer: false,
      search: "",
      radio: null,
      radioData: [],
      radioIndex: null,
      printTipDialog: false,
      dateData: ["昨日", "今日", "明日"],
      dateIndex: 1,
      shipmentsDate: "",
      fjztData: [
        { label: "全部", value: "" },
        { label: "已分拣", value: 1 },
        { label: "未分拣", value: 0 },
      ],
      fjztIndex: 0,
      fjztValue: "",
      sfbpData: [
        { label: "全部", value: "" },
        { label: "标品", value: 1 },
        { label: "未标品", value: 0 },
      ],
      sfbpIndex: 0,
      sfbpValue: "",
      lxData: [{ lineName: "全部", lineId: "" }],
      lxIndex: [],
      lxValue: [],
      lineId: "",
      gysData: [{ providerName: "全部供应商", providerId: "" }],
      printTipDialogSorting: false,
      warehouseData: [],
      warehouseValue: "",
      shsjData: [],
      shsjValue: "",
      shipmentsDate: "",
      typeList: [],
      firstTypeId: null,
      secondTypeId: "",
      goodsList: [],
      searchFlag: true,
      num: 0,
      printData: [],
      template: {},
      path: "",
      providerId: "",
    };
  },
  methods: {
    // 多个打印
    print() {
      this.printTipDialog = false;
      this.printTipDialogSorting = false;
      this.printData.clientPrintDataList.forEach((val) => {
        val.printCommoditieList.forEach((item) => {
          let template = val.printId != -1 ? val : this.printData.DefaultModel;
          PrintAccount(template, [], item);
        });
      });
    },
    sure() {
      this.firstTypeId = null;
      this.secondTypeId = null;
      this.radioIndex = null;
      this.radio = null;
      this.getTypeList();
      this.drawer = false;
    },
    radioChange(firstTypeId) {
      sessionStorage.setItem("radio", this.radio);
      this.firstTypeId = firstTypeId;
      this.secondTypeId = null;
      this.radioIndex = null;
      this.getTypeList(firstTypeId);
    },
    select() {
      this.getTypeList();
      this.search ? (this.searchFlag = false) : (this.searchFlag = true);
    },
    // 点击商品分拣查询接口
    getTypeList(firstTypeId) {
      api
        .getTypeList({
          identity: localStorage.identity,
          firstTypeId: this.firstTypeId,
          secondTypeId: this.secondTypeId,
          commodityName: this.search,
          shipmentsDate: this.shipmentsDate,
          sortingState: this.fjztValue,
          isRough: this.sfbpValue,
          deliveryTimeId: this.shsjValue,
          warehouseId: this.warehouseValue,
          lineId: this.path,
          providerId: this.providerId,
          content: 1,
        })
        .then((res) => {
          this.radioData = [{ label: "全部", value: null }];
          res.data.ctfList.forEach((item, index) => {
            this.radioData.push({
              value: item.firstTypeId,
              label: item.firstTypeName,
            });
          });
          this.typeList = res.data.ctList;
        });
    },
    // 点击分类查询商品数据
    getListByType() {
      api
        .getListByType({
          identity: localStorage.identity,
          firstTypeId: this.firstTypeId,
          secondTypeId: this.secondTypeId,
          commodityName: this.search,
          shipmentsDate: this.shipmentsDate,
          sortingState: this.fjztValue,
          isRough: this.sfbpValue,
          deliveryTimeId: this.shsjValue,
          warehouseId: this.warehouseValue,
          lineId: this.path,
          providerId: this.providerId,
          content: 2,
        })
        .then((res) => {
          console.log(res);
          this.goodsList = res.data;
        });
    },
    // 查询送货时间接口
    selectDeliveryTime() {
      api.selectDeliveryTime().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.shsjData.push({
            value: item.deliveryTimeId,
            label: item.deliveryTime,
          });
        });
      });
    },
    // 查询供应商接口
    queryProvider() {
      api.queryProvider().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.gysData.push(item);
        });
      });
    },
    // 查询线路接口
    selectDeliveryLine() {
      api.selectDeliveryLine().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.lxData.push(item);
        });
      });
    },
    // 查询仓库接口
    selectWarehouse() {
      api.selectWarehouse().then((res) => {
        console.log(res);
        res.data.forEach((item, index) => {
          this.warehouseData.push({
            value: item.warehouseId,
            label: item.warehouseName,
          });
        });
      });
    },
    getDay(num, str) {
      var today = new Date();
      var nowTime = today.getTime();
      var ms = 24 * 3600 * 1000 * num;
      today.setTime(parseInt(nowTime + ms));
      var oYear = today.getFullYear();
      var oMoth = (today.getMonth() + 1).toString();
      if (oMoth.length <= 1) oMoth = "0" + oMoth;
      var oDay = today.getDate().toString();
      if (oDay.length <= 1) oDay = "0" + oDay;
      return oYear + str + oMoth + str + oDay;
    },
    fhrqdj(index) {
      this.dateIndex = index;
      this.shipmentsDate = this.getDay(index, "-");
    },
    shouqiweishouqi(firstTypeId, secondTypeId, index) {
      sessionStorage.setItem("radioIndex", index);
      sessionStorage.setItem("firstTypeId", firstTypeId);
      sessionStorage.setItem("secondTypeId", secondTypeId);
      if (this.radioIndex == index) {
        this.radioIndex = null;
        this.secondTypeId = null;
      } else {
        this.radioIndex = index;
        this.firstTypeId = firstTypeId;
        this.secondTypeId = secondTypeId;
        this.getListByType();
      }
    },
    // 查询当前所有打印数据
    queryAllPrintData(type) {
      return new Promise((resolve) => {
        api
          .oneKeyPrint({
            sorterId: localStorage.identity,
            shipmentsDate: this.shipmentsDate,
            commodityId: this.commodityId,
            commodityMetering: this.commodityMetering,
            firstTypeId: this.firstTypeId,
            secondTypeId: this.secondTypeId,
            sortingStatus: type,
          })
          .then((res) => {
            console.log(res);
            this.num = 0;
            res.data.clientPrintDataList.forEach((val) => {
              this.num += val.printCommoditieList.length;
            });
            this.printData = res.data;
            resolve();
          });
      });
    },
    yjdy() {
      this.queryAllPrintData(1).then(() => {
        this.printTipDialog = true;
      });
    },
    yjfj(isAllow) {
      if (!isAllow) {
        this.queryAllPrintData(0).then(() => {
          this.printTipDialogSorting = true;
        });
      } else {
        if (this.num == 0) {
          this.$message.error("暂无可分拣商品");
          return;
        }
        api
          .sortingAllGoods({
            sorterId: localStorage.identity,
            shipmentsDate: this.shipmentsDate,
            firstTypeId: this.firstTypeId,
            keyword: this.search,
          })
          .then((res) => {
            if (res.state == 200) {
              this.print();
              this.getListByType();
              this.$message({ type: "success", message: "分拣成功" });
            } else {
              this.$message.error(res.message);
            }
          });
      }
    },
    goToAspfjDetail(item) {
      var obj = {
        commodityId: item.commodityId,
        commodityName: item.commodityName,
        state: item.numberSorted == item.unselectedNumber ? 1 : 0,
        shipmentsDate: this.shipmentsDate,
      };
      this.$router.push({
        path: "/aspfjDetail",
        query: obj,
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path == "/aspfjDetail") {
        vm.getListByType();
      }
    });
  },
  mounted() {
    this.shipmentsDate = localStorage.shipmentsDate;
    this.selectWarehouse();
    this.selectDeliveryLine();
    this.queryProvider();
    this.selectDeliveryTime();
    this.getTypeList();
    this.getListByType();
  },
};
</script>

<style scoped>
body {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* 标准语法 */
}
::-webkit-scrollbar {
  display: none;
}
.a {
  width: 100%;
  height: calc(10vh);
  background-color: black;
  display: flex;
  border-bottom: 1px solid grey;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* 标准语法 */
}
.b {
  width: 30%;
  display: flex;
}
.yjdy,
.sx,
.yjfj {
  margin: auto;
  color: #000;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* 标准语法 */
}
.c {
  width: 15%;
  height: 90vh;
  background-color: rgb(39, 37, 37);
  overflow: auto;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: rgb(66, 180, 133);
  font-size: 20px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: rgb(66, 180, 133);
  background: rgb(66, 180, 133);
}
::v-deep .el-radio__inner {
  border: 1px solid #dcdfe6;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
}
::v-deep .el-radio__label {
  font-size: 20px;
  padding-left: 10px;
  color: #000;
}
::v-deep .el-drawer__header {
  display: none;
}
>>> .el-radio__label {
  color: wheat;
}
</style>
