<template>
  <div style="background-color:#ddd;height:100vh">
    <!-- 顶部栏 -->
    <div class="a">
      <div style="width:7%;display:flex">
        <div
          style="display:flex;align-items:center;margin:auto"
          @click="$router.go(-1)"
        >
          <img src="../assets/back.png" alt style="width:30px;height:30px" />
          <div style="color:white;font-size:20px">返回</div>
        </div>
      </div>
      <div style="display:flex;width:63%">
        <el-input
          placeholder="请输入客户名称"
          prefix-icon="el-icon-search"
          v-model="search"
          style="margin:auto"
          @input="getListByGoods"
        ></el-input>
      </div>
      <div
        style="display:flex;width:40%;margin-left:10px;flex-flow: row-reverse;"
      >
        <div class="b" style="background-color:rgb(66,180,133);">
          <div class="yjfj" @click="drawerSort = true" style="font-size:15px">
            排序方式
          </div>
        </div>
        <div class="b" style="background-color:rgb(197,74,74);">
          <div class="yjfj" @click="yjfj()" style="font-size:15px">
            一键分拣
          </div>
        </div>
        <div class="b" style="background-color:rgb(66,180,133);">
          <div class="yjdy" @click="yjdy" style="font-size:15px">一键打印</div>
        </div>
      </div>
    </div>
    <!-- 顶部栏 -->
    <!-- 商品单位栏 -->
    <div
      style="display:flex;flex-direction:row;align-items:center;margin-left:20px"
    >
      <div style="font-weight:bold;font-size:20px">{{ commodityName }}</div>
      <div style="margin-left:20px">
        <div style="display:flex;flex-direction:row">
          <div
            style="width:120px;height:60px;border:1px solid grey;display:flex;position:relative;margin:10px"
            v-for="(item, index) in danweiData"
            :key="index"
            @click="danweidj(index, item.commodityMetering)"
          >
            <div
              style="margin:auto;color:rgb(66,151,178)"
              v-if="danweiIndex == index"
            >
              {{ item.commodityMetering }}
            </div>
            <div style="margin:auto" v-else>{{ item.commodityMetering }}</div>
            <img
              src="../assets/gou.png"
              alt
              style="width:50px;height:50px;position:absolute;bottom:0;right:0"
              v-if="danweiIndex == index"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 商品单位栏 -->
    <!-- 分拣统计 -->
    <div
      style="width:100%;display:flex;flex-direction:row;justify-content:space-between;background-color:grey;color:white"
    >
      <div style="margin:auto;height:50px;display:flex;">
        <div style="margin:auto">
          <span>分拣总数：</span>
          <span>{{ totalSorting }}{{ commodityMetering }}</span>
        </div>
      </div>
      <div style="margin:auto;height:50px;display:flex;">
        <div style="margin:auto">
          <span>已分拣总数：</span>
          <span>{{ totalSorted }}{{ commodityMetering }}</span>
        </div>
      </div>
      <div style="margin:auto;height:50px;display:flex;">
        <div style="margin:auto">
          <span>未分拣总数：</span>
          <span>{{ totalUnselected }}{{ commodityMetering }}</span>
        </div>
      </div>
    </div>
    <!-- 分拣统计 -->
    <!--商品 -->
    <div
      style="display:flex;flex-direction:row; flex-flow: row wrap;position:relative;max-height:75vh;overflow: auto;"
    >
      <div v-for="(item, index) in searchData" :key="index">
        <div
          :style="
            `width:300px;height:200px;background-color:${
              item.state == 0 ? 'rgb(197,74,74)' : 'rgb(66,180,133)'
            };border:1px solid grey;margin:10px;`
          "
        >
          <div
            style="position:absolute;width:35px;height:35px;border:1px solid #FF8C00;border-radius:25px;display:flex;left:20px;top:75px"
            v-if="item.isStockout == 1"
          >
            <div style="color:#FF8C00;font-size:10px;margin:auto">缺货</div>
          </div>
          <div
            style="display:flex;flex-direction:row;justify-content:space-between"
          >
            <div style="width:85%;margin:auto;color:white">
              <div style="height:50px;margin:auto;display:flex;font-size:17px">
                <div style="margin:auto">
                  <span>[{{ item.clientNo }}]</span>
                  <span style="margin:5px">{{ item.clientName }}</span>
                </div>
              </div>
              <div style="height:75px;margin:auto;display:flex;">
                <div style="margin:auto;font-size:25px">
                  <div>{{ item.orderNum }}{{ item.commodityMetering }}</div>
                </div>
              </div>
              <div style="height:75px;margin:auto;display:flex;">
                <div style="margin:auto">
                  <div>
                    称重：{{ item.practicalNum }}{{ item.commodityMetering }}
                  </div>
                  <div>
                    {{ item.tips }}
                  </div>
                </div>
              </div>
            </div>
            <div
              style="width:15%;margin:auto;display:flex;;background-color:white;height:200px;text-align: center;"
            >
              <div style="margin:auto;font-size:25px">
                <div @click="cz(item)">操作</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--商品 -->
    <!-- 排序方式弹框 -->
    <el-drawer :visible.sync="drawerSort" direction="ttb" size="25%">
      <div>
        <div>
          <div style="display:flex;flex-direction:row;flex-wrap: wrap;">
            <div
              style="width:200px;height:80px;border:1px solid grey;display:flex;position:relative;margin:10px"
              v-for="(item, index) in pxfsData"
              :key="index"
            >
              <div
                style="display:flex;flex-direction:row;width:200px;height:100%"
                @click="pxfsIndex = index"
              >
                <span style="margin:auto">{{ item }}</span>
              </div>
              <img
                src="../assets/gou.png"
                alt
                style="width:50px;height:50px;position:absolute;bottom:0;right:0"
                v-if="pxfsIndex == index"
              />
            </div>
          </div>
        </div>
        <div
          style="display:flex;flex-direction:row;justify-content:space-around;height:50%;margin-top:10px"
        >
          <div
            style="display:flex;flex-direction:row;width:49%;height:70px;border:1px solid black"
            @click="drawerSort = false"
          >
            <div style="margin:auto;font-size:20px">取消</div>
          </div>
          <div
            style="display:flex;flex-direction:row;width:49%;height:70px;background-color:rgb(66,151,178);color:white"
            @click="drawerSort = false"
          >
            <div style="margin:auto;font-size:20px">确定</div>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 排序方式弹框 -->
    <!-- 分拣输入弹框 -->
    <el-dialog
      :title="commodityName + ' [ ' + clientNo + ' - ' + clientName + ' ] '"
      :visible.sync="sortingInputDialog"
      width="65%"
    >
      <!-- 顶部栏 -->
      <div style="width:100%;display:flex;flex-direction:row;">
        <div style="width:50%;display:flex;flex-direction:row;height:60px">
          <div
            :style="
              `width:33%;display:flex;background-color:${
                fjIndex == 0 ? 'rgb(197,74,74)' : '#E6E6FA'
              };color:black`
            "
            @click="fjIndex = 0"
          >
            <div style="margin:auto;font-size:20px">分拣</div>
          </div>
          <div
            :style="
              `width:33%;display:flex;background-color:${
                fjIndex == 1 ? 'rgb(197,74,74)' : '#E6E6FA'
              };color:black`
            "
            @click="fjIndex = 1"
          >
            <div style="margin:auto;font-size:20px">多次分拣</div>
          </div>
        </div>
        <div
          style="display:flex;flex-direction:row;justify-content:space-evenly;"
        >
          <div
            style="width:100px;display:flex;background-color:rgb(66,180,133);color:white;margin-left:10px"
            @click="reset()"
            v-if="viewDetailsData.sortingRecord != 0 || isStockout == 1"
          >
            <div style="margin:auto;font-size:30px">重置</div>
          </div>
          <div
            @click="printSingle"
            v-if="fenjianFlag"
            style="width:100px;display:flex;background-color:rgb(66,151,178);color:white;margin-left:10px"
          >
            <div style="margin:auto;font-size:30px">打印</div>
          </div>
          <div
            style="width:150px;display:flex;background-color:orange;color:white;margin-left:10px"
            v-if="!fenjianFlag && isStockout != 1"
            @click="bjqh()"
          >
            <div style="margin:auto;font-size:30px">标记缺货</div>
          </div>
        </div>
      </div>
      <div style="margin:20px 0 20px 0">
        <span style="font-size:20px">订购数量：</span>
        <span style="font-size:20px;color:rgb(197,74,74)">
          {{ viewDetailsData.orderNum }}
          {{ viewDetailsData.commodityMetering }}
        </span>
      </div>
      <!-- 顶部栏 -->
      <el-input
        v-model="input"
        style="font-size:35px"
        :disabled="inputFlag && fjIndex == 0"
      ></el-input>
      <!-- 分拣记录 -->
      <div
        v-show="fjIndex == 1"
        style="display:flex;flex-direction:row;justify-content:space-between;margin:20px 0 20px 0;font-size:20px"
      >
        <div>
          <span>分拣记录：</span>
          <span>{{ viewDetailsData.sortingRecord }}</span>
        </div>
        <div style="display:flex;flex-direction:row">
          <div>
            <span>分拣总和：</span>
            <span
              >{{ viewDetailsData.sortingRecord
              }}{{ viewDetailsData.commodityMetering }}</span
            >
          </div>
          <div style="margin-left:20px">
            <span>未分拣量：</span>
            <span
              >{{
                0 > viewDetailsData.unsortedQuantity
                  ? 0
                  : viewDetailsData.unsortedQuantity
              }}{{ viewDetailsData.commodityMetering }}</span
            >
          </div>
        </div>
      </div>
      <!-- 分拣记录 -->
      <!-- 操作按钮 -->
      <div
        v-show="!fenjianFlag || fjIndex == 1"
        style="display: flex;flex-wrap: wrap; margin-top: 20px;"
      >
        <div
          v-for="item in btnList"
          :key="item"
          @click="btnClick(item)"
          class="btn"
          :style="item == '完成' && fjIndex == 0 ? 'width: 50%;' : ''"
          v-show="item != '记录称重' || fjIndex == 1"
        >
          {{ item }}
        </div>
      </div>
      <!-- 操作按钮 -->
      <div v-show="fenjianFlag && fjIndex == 0">
        <div style="width:100%;height:300px;display:flex">
          <div style="margin:auto;font-size:50px">已分拣</div>
        </div>
      </div>
    </el-dialog>
    <!-- 分拣输入弹框 -->
    <!-- 一键打印提示框 -->
    <el-dialog title="提示" :visible.sync="printTipDialog" width="30%">
      <span>是否确定一键打印{{ num }}个标签</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="printTipDialog = false">取消</el-button>
        <el-button type="primary" @click="print()">确定</el-button>
      </span>
    </el-dialog>
    <!-- 一键打印提示框 -->
    <!-- 一键分拣提示框 -->
    <el-dialog title="提示" :visible.sync="printTipDialogSorting" width="30%">
      <span
        >将有{{
          num
        }}个标签被打印（注意：此功能会分拣并打印所有未分拣的商品），确定全部打印？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="printTipDialogSorting = false">取消</el-button>
        <el-button type="primary" @click="yjfj(true)">确定</el-button>
      </span>
    </el-dialog>
    <!-- 一键分拣提示框 -->
  </div>
</template>

<script>
import api from "../apis/aspfj/index";
import { PrintAccount } from "../print/doPrint";
export default {
  data() {
    return {
      btnList: [
        1,
        2,
        3,
        "删除",
        4,
        5,
        6,
        "清空",
        7,
        8,
        9,
        ".",
        "返回",
        0,
        "记录称重",
        "完成",
      ],
      inputFlag: false,
      input: "",
      search: "",
      printTipDialog: false,
      printTipDialogSorting: false,
      danweiData: [],
      danweiIndex: null,
      btszData: [
        "商品备注",
        "订单备注",
        "供应商/采购员",
        "内部备注",
        "库区库位",
      ],
      drawerSort: false,
      pxfsData: [
        "默认排序",
        "按客户名称",
        "按客户编码",
        "按下单数量",
        "按线路",
      ],
      pxfsIndex: 0,
      sortingInputDialog: false,
      fjIndex: 0,
      commodityId: "",
      shipmentsDate: "",
      commodityName: "",
      commodityMetering: "",
      totalSorted: "",
      totalSorting: "",
      totalUnselected: "",
      searchData: [],
      fenjianFlag: false,
      clientNo: "",
      clientName: "",
      viewDetailsData: [],
      state: "",
      isStockout: "",
      printData: [],
      num: 0,
      selectItem: {},
    };
  },
  methods: {
    // 单个打印
    printSingle() {
      api
        .print({
          identity: localStorage.identity,
          shipmentsDate: this.viewDetailsData.shipmentsDate,
          commodityMetering: this.viewDetailsData.commodityMetering,
          commodityId: this.viewDetailsData.commodityId,
          orderCommodityId: this.viewDetailsData.orderCommodityId,
          sorterBillTemplateId: this.selectItem.sorterBillTemplateId,
        })
        .then((res) => {
          console.log(res);
          res.data.forEach((val) => {
            PrintAccount(res.data2, [], val);
          });
        });
    },
    // 多个打印
    print() {
      this.printTipDialog = false;
      this.printTipDialogSorting = false;
      this.printData.clientPrintDataList.forEach((val) => {
        val.printCommoditieList.forEach((item) => {
          let template = val.printId != -1 ? val : this.printData.DefaultModel;
          PrintAccount(template, [], item);
        });
      });
    },
    // 标记缺货按钮
    bjqh() {
      this.$confirm("是否确定标记为缺货?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        api.signOutStock(this.viewDetailsData.orderCommodityId).then((res) => {
          if (res.state == 200) {
            this.$message({
              type: "success",
              message: "标记为缺货成功!",
            });
            this.sortingInputDialog = false;
            this.getListByGoods();
          }
        });
      });
    },
    // 重置按钮
    reset() {
      this.$confirm("重置成功后，历史称重数据将被清除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api
            .resetSorting({
              orderCommodityId: this.viewDetailsData.orderCommodityId,
              orderId: this.viewDetailsData.orderId,
              commodityId: this.viewDetailsData.commodityId,
              sortingUnits: this.viewDetailsData.sortingUnits,
            })
            .then((res) => {
              if (res.state == 200) {
                this.$message({
                  type: "success",
                  message: "重置成功!",
                });
                this.sortingInputDialog = false;
                this.getListByGoods();
              }
            });
        })
        .catch(() => {});
    },
    // 分拣按钮操作
    btnClick(item) {
      if (Number(item) + "" != "NaN") {
        this.input += item;
      }
      if (item == "删除") {
        this.input = this.input.slice(0, -1);
      }
      if (item == "清空") {
        this.input = "";
      }
      if (item == "返回") {
        this.sortingInputDialog = false;
      }
      if (item == "." && !this.input.match(/\./g) && this.input != "") {
        this.input += ".";
      }
      if (
        (item == "完成" || item == "记录称重") &&
        this.input != "" &&
        this.input != 0
      ) {
        if (this.input.substr(this.input.indexOf(".")).length > 3) {
          this.$message({
            message: "只能输入两位小数",
            type: "error",
          });
          return;
        }
        if (this.input.indexOf(".") == this.input.length - 1) return;
        api[this.fjIndex ? "manyTimesSorting" : "sortingCommodity"]({
          orderCommodityId: this.orderCommodityId,
          sortingRecord: parseFloat(this.viewDetailsData.sortingRecord),
          thisNum: parseFloat(this.input),
          orderNum: parseFloat(this.viewDetailsData.orderNum),
          type: item == "完成" ? 1 : 0,
          sortingUnits: this.viewDetailsData.commodityMetering,
          practicalNum: parseFloat(this.input),
        }).then((res) => {
          console.log(res);
          if (res.state == 200) {
            this.getListByGoods();
            this.sortingInputDialog = false;
            this.$message({
              message: "操作成功",
              type: "success",
            });
            api
              .print({
                identity: localStorage.identity,
                shipmentsDate: this.viewDetailsData.shipmentsDate,
                commodityMetering: this.viewDetailsData.commodityMetering,
                commodityId: this.viewDetailsData.commodityId,
                orderCommodityId: this.viewDetailsData.orderCommodityId,
                num: this.input * 1,
                sorterBillTemplateId: this.selectItem.sorterBillTemplateId,
              })
              .then((res) => {
                console.log(res);
                this.input = "";
                res.data.forEach((val) => {
                  PrintAccount(res.data2, [], val);
                });
              });
          }
        });
      }
    },
    // 查询商品数据
    getListByGoods() {
      api
        .getListByGoods({
          identity: localStorage.identity,
          commodityId: this.commodityId,
          commodityMetering: this.commodityMetering,
          shipmentsDate: this.shipmentsDate,
          clientName: this.search,
          sort: this.pxfsIndex,
          content: 3,
        })
        .then((res) => {
          console.log(res);
          this.searchData = res.data;
          this.totalSorted = res.data2.totalSorted;
          this.totalSorting = res.data2.totalSorting;
          this.totalUnselected = res.data2.totalUnselected;
        });
    },
    // 操作按钮
    cz(item) {
      console.log(item);
      this.selectItem = item;
      this.clientNo = item.clientNo;
      this.clientName = item.clientName;
      this.orderCommodityId = item.orderCommodityId;
      this.isStockout = item.isStockout;
      this.fenjianFlag = item.state == 1;
      this.inputFlag = item.state == 1;
      this.sortingInputDialog = true;
      api.viewDetails(this.orderCommodityId).then((res) => {
        console.log(res);
        this.viewDetailsData = res.data;
      });
    },
    // 查询当前所有打印数据
    queryAllPrintData(type) {
      return new Promise((resolve) => {
        api
          .oneKeyPrint({
            sorterId: localStorage.identity,
            shipmentsDate: this.shipmentsDate,
            commodityId: this.commodityId,
            commodityMetering: this.commodityMetering,
            sortingStatus: type,
          })
          .then((res) => {
            console.log(res);
            this.num = 0;
            res.data.clientPrintDataList.forEach((val) => {
              this.num += val.printCommoditieList.length;
            });
            this.printData = res.data;
            resolve();
          });
      });
    },
    // 一键打印按钮
    yjdy() {
      this.queryAllPrintData(1).then(() => {
        this.printTipDialog = true;
      });
    },
    // 一键分拣按钮
    yjfj(isAllow) {
      if (!isAllow) {
        this.queryAllPrintData(0).then(() => {
          this.printTipDialogSorting = true;
        });
      } else {
        if (this.num == 0) {
          this.$message.error("暂无可分拣商品");
          return;
        }
        api
          .sortingAllGoods({
            sorterId: localStorage.identity,
            shipmentsDate: this.shipmentsDate,
            commodityId: this.commodityId,
            commodityMetering: this.commodityMetering,
          })
          .then((res) => {
            console.log(res);
            if (res.state == 200) {
              this.$message({ type: "success", message: "分拣成功" });
              this.print();
              this.getListByGoods();
            } else {
              this.$message.error(res.message);
            }
          });
      }
    },
    danweidj(index, commodityMetering) {
      if (this.danweiIndex == index) {
        this.danweiIndex = null;
        this.commodityMetering = null;
      } else {
        this.danweiIndex = index;
        this.commodityMetering = commodityMetering;
      }
      this.getListByGoods();
    },
  },
  mounted() {
    this.danweiData = [];
    this.commodityId = this.$route.query.commodityId;
    this.shipmentsDate = this.$route.query.shipmentsDate;
    this.commodityName = this.$route.query.commodityName;
    this.state = this.$route.query.state;
    api
      .getUnits({
        shipmentsDate: this.shipmentsDate,
        commodityId: this.commodityId,
      })
      .then((res) => {
        this.danweiData = res.data;
        this.danweiIndex = 0;
        this.commodityMetering = res.data[0].commodityMetering;
        this.getListByGoods();
      });
  },
};
</script>

<style scoped>
body {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* 标准语法 */
}
.btn {
  display: flex;
  background-color: #e6e6fa;
  width: 25%;
  border: 1px solid black;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  padding: 15px;
  color: #000;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* 标准语法 */
}
.a {
  width: 100%;
  height: calc(10vh);
  background-color: black;
  display: flex;
  border-bottom: 1px solid grey;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* 标准语法 */
}
.b {
  width: 30%;
  display: flex;
}
.yjdy,
.sx,
.yjfj {
  margin: auto;
  color: white;
}
.c {
  width: 15%;
  height: calc(95vh);
  background-color: rgb(39, 37, 37);
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: rgb(66, 180, 133);
  font-size: 20px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: rgb(66, 180, 133);
  background: rgb(66, 180, 133);
}
::v-deep .el-radio__inner {
  border: 1px solid #dcdfe6;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
}
::v-deep .el-radio__label {
  font-size: 20px;
  padding-left: 10px;
  color: white;
}
::v-deep .el-drawer__header {
  display: none;
}
</style>
