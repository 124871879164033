import axios from "axios";

const service = axios.create({
  // baseURL: "http://192.168.1.53:8084", //
  baseURL: "https://www.cqyucx.com/prod-api/", //菜到佳erp
  // baseURL: "https://erp.yucaixiang.cqyucx.com/prod-api/", //于菜箱净菜车间erp
  // baseURL:"https://erp.hotpot.cqyucx.com/prod-api/",
  // baseURL: "http://47.96.89.152:8071", // 集团erp
  // baseURL: "https://erp.jcdj.cqyucx.com/prod-api/", // 集材到家erp
  //设置请求头token
  headers: {
    token: sessionStorage.getItem("token"),
  },
});
//请求拦截器
service.interceptors.request.use(
  (config) => {
    config.params = {
      _t: new Date().getTime(),
      ...config.params,
    };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
//响应拦截器
service.interceptors.response.use((response) => {
  // if (response.data.status) {
  //   if (response.data.status == "520") {
  //     alert("你的登录已过期,请重新登陆");
  //     location.replace("/");
  //   }
  // }
  return response;
});
export default service;
