<template>
  <div>
    <div class="haha">
      <img
        src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.vjshi.com%2F2019-04-23%2F43b4675aea47b0d57d9404f03aefa7bf%2F00003.jpg%3Fx-oss-process%3Dstyle%2Fwatermark&refer=http%3A%2F%2Fpic.vjshi.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1638411455&t=0ee16f0d388868823fc49328ee413222"
        alt
        style="width:100%;height:99vh;position:relative"
      />
      <div
        style="color:white;position:absolute;top:50px;width:100%;display:flex;fontSize:30px"
      >
        <div style="margin:auto">Windows端分拣系统</div>
      </div>
      <div
        style="color:white;position:absolute;top:50px;right:50px;display:flex;fontSize:20px;"
      >
        <div
          style="display:flex;flex-direction:row;alignItems:center"
          @click="zhuxiao"
        >
          <img src="../assets/zhuxiao.png" alt style="width:20px;height:20px" />
          <div style="margin-left:10px">注销</div>
        </div>
        <div
          @click="close1()"
          style="display:flex;flex-direction:row;alignItems:center;margin-left:20px"
        >
          <img src="../assets/guanbi.png" alt style="width:20px;height:20px" />
          <div style="margin-left:10px">关闭</div>
        </div>
      </div>
      <div
        style="color:grey;position:absolute;top:100px;right:50px;display:flex;fontSize:15px;"
      >
        <div>版本号：1.0.0</div>
      </div>
      <div
        style="width:100%;height:450px;position:absolute;top:150px;display:flex"
      >
        <div class="a">
          <div style="display:flex;justify-content:space-around">
            <div style="textAlign:center" @click="gotoAspfj">
              <div class="spfj">
                <img
                  src="../assets/spfj.png"
                  alt
                  style="margin:auto;width:80px;height:80px"
                />
              </div>
              <div style="color:white;fontSize:25px;margin-top:10px">
                按商品分拣
              </div>
            </div>
            <div style="textAlign:center" @click="gotoAkhfj">
              <div class="khfj">
                <img
                  src="../assets/khfj.png"
                  alt
                  style="margin:auto;width:80px;height:80px"
                />
              </div>
              <div style="color:white;fontSize:25px;margin-top:10px">
                按客户分拣
              </div>
            </div>
            <div style="textAlign:center" @click="gotoXtsz">
              <div class="xtsz">
                <img
                  src="../assets/xtsz.png"
                  alt
                  style="margin:auto;width:80px;height:80px"
                />
              </div>
              <div style="color:white;fontSize:25px;margin-top:10px">
                系统设置
              </div>
            </div>
            <div style="textAlign:center" @click="gotoQhsp">
              <div class="qhsp">
                <img
                  src="../assets/qhsp.png"
                  alt
                  style="margin:auto;width:80px;height:80px"
                />
              </div>
              <div style="color:white;fontSize:25px;margin-top:10px">
                缺货商品
              </div>
            </div>
          </div>
          <div style="display:flex;justify-content:space-around">
            <div style="textAlign:center" @click="gotoDdfjcy">
              <div class="fjcy">
                <img
                  src="../assets/xmsg.png"
                  alt
                  style="margin:auto;width:80px;height:80px"
                />
              </div>
              <div style="color:white;fontSize:25px;margin-top:10px">
                订单分拣差异
              </div>
            </div>
            <div style="textAlign:center" @click="gotoYdb">
              <div class="ydb">
                <img
                  src="../assets/xmsg.png"
                  alt
                  style="margin:auto;width:80px;height:80px"
                />
              </div>
              <div style="color:white;fontSize:25px;margin-top:10px">
                预打包
              </div>
            </div>
            <div style="textAlign:center" @click="gotoSmfj">
              <div class="smfj">
                <img
                  src="../assets/xmsg.png"
                  alt
                  style="margin:auto;width:80px;height:80px"
                />
              </div>
              <div style="color:white;fontSize:25px;margin-top:10px">
                扫码分拣
              </div>
            </div>
            <div style="visibility: hidden">
              <div class="smfj"></div>
              <div style="color:white;fontSize:25px">哦牛皮</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: "",
    };
  },
  methods: {
    close1() {
      console.log(11);
      window.close();
    },
    zhuxiao() {
      if (this.checked == 1) {
        this.$router.push("/");
      }
      if (this.checked == 2) {
        this.$router.push("/");
        localStorage.clear();
      }
      if (this.$route.query.checked == undefined) {
        console.log("aaaa");
        console.log(this.checked);
        this.$router.push("/");
      }
    },
    gotoAspfj() {
      this.$router.push("/aspfj");
    },
    gotoAkhfj() {
      this.$router.push("/akhfj");
    },
    gotoQhsp() {
      this.$router.push("/qhsp");
    },
    gotoXtsz() {
      this.$alert("该功能尚未开放", {
        confirmButtonText: "确定",
      });
    },
    gotoDdfjcy() {
      this.$router.push("/ddfjcy");
    },
    gotoYdb() {
      this.$alert("该功能尚未开放", {
        confirmButtonText: "确定",
      });
    },
    gotoSmfj() {
      this.$alert("该功能尚未开放", {
        confirmButtonText: "确定",
      });
    },
  },
  mounted() {
    console.log(localStorage.shipmentsDate);
    console.log(this.$route.query.checked);
    if (this.$route.query.checked == 1) {
      this.checked = 1;
    }
    if (this.$route.query.checked == 2) {
      this.checked = 2;
    }
  },
};
</script>

<style scoped>
.haha {
  width: 100%;
  height: 100vh;
  background-image: url(https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.vjshi.com%2F2019-04-23%2F43b4675aea47b0d57d9404f03aefa7bf%2F00003.jpg%3Fx-oss-process%3Dstyle%2Fwatermark&refer=http%3A%2F%2Fpic.vjshi.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1638411455&t=0ee16f0d388868823fc49328ee413222);
}
.a {
  background-color: rgb(27, 52, 80);
  width: 95%;
  height: calc(70vh);
  margin: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  opacity: 0.8;
  justify-content: space-evenly;
}
.spfj {
  width: 150px;
  height: 150px;
  border-radius: 50px;
  background-image: linear-gradient(rgb(253, 77, 161), rgb(170, 27, 89));
  display: flex;
}
.khfj {
  width: 150px;
  height: 150px;
  border-radius: 50px;
  background-image: linear-gradient(rgb(249, 201, 81), rgb(153, 102, 37));
  display: flex;
}
.xtsz {
  width: 150px;
  height: 150px;
  border-radius: 50px;
  background-image: linear-gradient(rgb(48, 150, 246), rgb(18, 73, 157));
  display: flex;
}
.qhsp {
  width: 150px;
  height: 150px;
  border-radius: 50px;
  background-image: linear-gradient(rgb(172, 100, 245), rgb(80, 4, 160));
  display: flex;
}
.fjcy,
.ydb,
.smfj {
  width: 150px;
  height: 150px;
  border-radius: 50px;
  background-image: linear-gradient(rgb(61, 162, 246), rgb(8, 100, 178));
  display: flex;
}
</style>
